import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const [menu, setMenu] = useState(true);
  const [dropMenu, setDropMenu] = useState(true);
  const [deepMenu, setDeepMenu] = useState(true);
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 6 second!', counter);

      console.log('##########ADD#############');
      // setCounter(prev => prev + 1);
      setCounter((counter) => counter + 1);
      setCount((count) => count + 1);
      handleCarouselRight();
    }, 6000);
    return () => clearInterval(interval);
  }, []);
  console.log('#####################', counter, '##############');
  if (counter === 5) {
    setCounter(0);
  }

  console.log('#####################', count, '##############');
  if (count === 5) {
    setCount(0);
  }

  function handleClick(e) {
    if (e) {
      e.preventDefault();
    }
    setMenu(!menu);

    console.log('#####################clicked ##############');
  }

  function handleDropdown(e) {
    if (e) {
      e.preventDefault();
    }
    setDropMenu(!dropMenu);
    console.log('#####################clicked ##############');
  }

  function handleDeepdown(e) {
    if (e) {
      e.preventDefault();
    }
    setDeepMenu(!deepMenu);
    console.log('#####################clicked ##############');
  }

  function handleCarouselLeft(e) {
    if (e) {
      e.preventDefault();
    }
    if (count === 0) {
      setCount(4);
    } else {
      setCount(count - 1);
    }

    console.log('#####################clicked ##############');
  }

  function handleCarouselRight(e) {
    if (e) {
      e.preventDefault();
    }

    if (count === 5) {
      setCount(0);
    } else {
      setCount(count + 1);
    }

    console.log('#####################clicked ##############');
  }

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      console.log('############### TRY SENDING MAIL @@@@@@@@@@@@');
      console.log(
        '###############',
        name,
        email,
        subject,
        message,
        '@@@@@@@@@@@@'
      );
      const response = await axios.post('/send-email', {
        name,
        email,
        subject,
        message,
      });

      if (response.status === 200) {
        setStatus('success');
        // Reset form fields
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div>
      <div>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Multi Bootstrap Template - Index</title>
        <meta content name="description" />
        <meta content name="keywords" />

        <link
          href={process.env.PUBLIC_URL + 'assets/img/favicon.png'}
          rel="icon"
        />
        <link
          href={process.env.PUBLIC_URL + 'assets/img/logo.png'}
          // href={process.env.PUBLIC_URL + 'assets/img/apple-touch-icon.png'}
          rel="apple-touch-icon"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />

        <link
          href="assets/vendor/animate.css/animate.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/glightbox/css/glightbox.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="assets/vendor/swiper/swiper-bundle.min.css"
          rel="stylesheet"
        />

        <link href="assets/css/style.css" rel="stylesheet" />
        {/* =======================================================
  * Template Name: Multi - v4.9.1
  * Template URL: https://bootstrapmade.com/multi-responsive-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}
        {/* ======= Header ======= */}
        <header-top id="header" className="fixed-top">
          <div className="container d-flex align-items-center justify-content-between sticked">
            {/* <h1 className="logo">
              <a href="/">Multi</a>
            </h1> */}
            {/* Uncomment below if you prefer to use an image logo */}
            <a href="/" className="logo">
              <img src="assets/img/logo.png" alt="" className="img-fluid" />
            </a>
            <nav id="navbar" className={menu ? 'navbar' : 'navbar-mobile'}>
              {/* <nav id="navbar" className="navbar"> */}
              <ul>
                <li>
                  <a className="nav-link scrollto " href="/">
                    {/* <a className="nav-link scrollto active" href="/"> */}
                    Home
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#about">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="#services">
                    Services
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="#portfolio">
                    Fleet
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Packages">
                    Package
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Booking">
                    Booking
                  </a>
                </li>
                {/* <li>
                  <a className="nav-link scrollto" href="#team">
                    Team
                  </a>
                </li> */}
                {/* <li className="dropdown">
                  {/* <li className={dropMenu}> */}
                {/* <a href="#">
                    <span>Our Offerings</span>{' '}
                    <i
                      className="bi bi-chevron-down"
                      onClick={handleDropdown}
                    />
                  </a>
                  <ul className={dropMenu ? '' : 'dropdown-active'}>
                    <li>
                      <a href="#">Book a Cab</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">
                        <span>Customer service</span>{' '}
                        <i
                          className="bi bi-chevron-right"
                          onClick={handleDeepdown}
                        />
                      </a>
                      <ul className={deepMenu ? '' : 'dropdown-active'}>
                        <li>
                          <a href="#">@Bangalore Office</a>
                        </li>
                        <li>
                          <a href="#">@Booking Related Query</a>
                        </li>
                        <li>
                          <a href="#">@Billing Query</a>
                        </li>
                        <li>
                          <a href="#">@Package Query</a>
                        </li>
                        <li>
                          <a href="#">@Make a Payment </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Package enquiry</a>
                    </li>
                    <li>
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <a href="#">Feed Back</a>
                    </li>
                  </ul> */}
                {/* </li> */}
                {/* <li>
                  <a className="nav-link scrollto" href="#contact">
                    Contact
                  </a>
                </li> */}
                {/* <li>
                  <a className="getstarted scrollto" href="#about">
                    Get Started
                  </a>
                </li> */}
              </ul>
              <i
                className={
                  menu
                    ? 'bi bi-list mobile-nav-toggle'
                    : 'bi  mobile-nav-toggle bi-x'
                }
                onClick={handleClick}
              />
              {/* <i className="bi bi-list mobile-nav-toggle" /> */}
            </nav>
            <div>
              {/* <i className="transprofessionals">
                <p className="blankLineP">{`Transprofessionals `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              {/* <i className="toursAndTravelsPvtLtd">
                <p className="blankLineP">{`Tours and Travels Pvt Ltd `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              <div className="transprofessionals">Transprofessionals</div>
              <div className="toursAndTravelsPvtLtd">
                Tours and Travels Pvt Ltd{' '}
              </div>
            </div>

            {/* .navbar */}
          </div>
        </header-top>

        {/* ======= Hero Section ======= */}
        <section id="hero">
          <div
            id="heroCarousel"
            data-bs-interval={5000}
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <ol className="carousel-indicators" id="hero-carousel-indicators" />
            <div className="carousel-inner" role="listbox">
              {/* Slide 1 */}
              <div
                className={
                  count === 0 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-1.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Service Orientation <span></span>
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      # Over 12 years of experience in corporate employee
                      transport, catering to major corporate's.
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                    <a
                      href="/Booking"
                      className="btn-get-started btn-book-now animate__animated animate__fadeInUp scrollto"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 2 */}
              <div
                className={
                  count === 1 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-2.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Customer Centric
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      # Rent A Cab For Airport Drop and Pick up, Daily disposal,
                      Local and outstation Sight seeing.
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                    <a
                      href="/Packages"
                      className="btn-get-started btn-book-now animate__animated animate__fadeInUp scrollto"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 3 */}
              <div
                className={
                  count === 2 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-3.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Deliver Safety
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      '# Experienced drivers will ensure your safe travel with
                      the best in class well maintained vehicles '
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                    <a
                      href="/Packages"
                      className="btn-get-started btn-book-now animate__animated animate__fadeInUp scrollto"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 4 */}
              <div
                className={
                  count === 3 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-4.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Quality Driven <span></span>
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      # All vehicles are installed with GPS monitoring systems
                      for ease of tracking, client/customer can track their
                      assigned vehicle on the transpor app.
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                    <a
                      href="/Packages"
                      className="btn-get-started btn-book-now animate__animated animate__fadeInUp scrollto"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 5 */}
              <div
                className={
                  count === 4 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-5.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Limitless Options
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      # We cater to company outings, family outings, outstation
                      holiday trips, School outings and Local sight seeing ' and
                      many more.
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                    <a
                      href="/Packages"
                      className="btn-get-started btn-book-now animate__animated animate__fadeInUp scrollto"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
              {/* Slide 6 */}
              {/* <div
                className={
                  count === 5 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-6.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Deliver Safety
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      '#We cater to company outings,family outings,outstation
                      holiday trips, School outings and Local sight seeing '
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div> */}
              {/* Slide 7 */}
              {/* <div
                className={
                  count === 6 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-7.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Service Orientation <span></span>
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      #Over 12 years of experience in corporate employee
                      transport, catering to major corporate's.
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div> */}
              {/* Slide 8 */}
              {/* <div
                className={
                  count === 7 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-8.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Customer Centric
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      #Rent A Cab For Airport Drop and Pick up, Daily disposal,
                      Local and outstation Sight seeing
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div> */}
              {/* Slide 9 */}
              {/* <div
                className={
                  count === 8 ? 'carousel-item active' : 'carousel-item '
                }
                style={{ backgroundImage: 'url(assets/img/slide/slide-9.jpg)' }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Deliver Safety
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      '#We cater to company outings,family outings,outstation
                      holiday trips, School outings and Local sight seeing '
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div> */}
              {/* Slide 10 */}
              {/* <div
                className={
                  count === 9 ? 'carousel-item active' : 'carousel-item '
                }
                style={{
                  backgroundImage: 'url(assets/img/slide/slide-10.jpg)',
                }}
              >
                <div className="carousel-container">
                  <div className="container">
                    <h2 className="animate__animated animate__fadeInDown">
                      Deliver Safety
                    </h2>
                    <p className="animate__animated animate__fadeInUp">
                      '#We cater to company outings,family outings,outstation
                      holiday trips, School outings and Local sight seeing '
                    </p>
                    <a
                      href="#about"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#heroCarousel"
              role="button"
              data-bs-slide="prev"
              onClick={handleCarouselLeft}
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              />
            </a>
            <a
              className="carousel-control-next"
              href="#heroCarousel"
              role="button"
              data-bs-slide="next"
              onClick={handleCarouselRight}
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              />
            </a>
          </div>
        </section>
        {/* End Hero */}
        <main id="main">
          {/* ======= About Section ======= */}
          <section id="about" className="about">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>About</h2>
                <p>About Us</p>
              </div>
              {/* <img
            className="bg-logo"
            alt=""
            src="assets/img/bglogo.png"
          /> */}
              <div className="row content">
                <div className="col-lg-6 pt-4 pt-lg-0">
                  <p>
                    Established in the year 2008, we believe that customer
                    satisfaction is as important as our service, this has helped
                    us to establish a firm foothold in the travel industry. Our
                    employees are dedicated to their respective roles and put a
                    lot of effort to achieve the common vision and larger goals
                    of the company.
                  </p>
                  <a href="#" className="btn-learn-more">
                    Learn More
                  </a>
                </div>
                <div className="col-lg-6">
                  <p>
                    We take pride in introducing ourselves as one of the
                    preferred employee transport and car hire partners for many
                    corporate's in Bangalore and other major cities across the
                    country. .
                  </p>
                  <ul>
                    <li>
                      <i className="ri-check-double-line" />
                      We are the preferred employee transport service provider
                      across many MNC's.
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Long term and
                      reliable partners to achieve common goals.
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Trained staff and
                      infrastructure.
                    </li>
                    <li>
                      <i className="ri-check-double-line" /> Affordable packages
                      and custom designed pricing.
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-6 pt-4 pt-lg-0"> */}
              </div>
            </div>
          </section>
          {/* End About Section */}
          {/* ======= Counts Section ======= */}
          <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
              <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-emoji-smile" />
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={232}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      32
                    </span>
                    <p>
                      <strong>Happy Clients:</strong> Service Orientated mindset
                      to gain client satisfaction
                    </p>
                    <a href="#">Find out more »</a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext" />
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={521}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      16
                    </span>
                    <p>
                      <strong>Projects:</strong> Current Projects with many
                      corporate clients
                    </p>
                    <a href="#">Find out more »</a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-headset" />
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={1463}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      30000
                    </span>
                    <p>
                      <strong>Hours Of Support:</strong> 24/7 365- days...
                      Dedicated team to support, Trained staff to understand
                      clients’ need.
                    </p>
                    <a href="#">Find out more »</a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-people" />
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={15}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      70
                    </span>
                    <p>
                      <strong>Hard Workers:</strong>Trained drivers,24/7 Support
                      and Dedicated backend team.
                    </p>
                    <a href="#">Find out more »</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Counts Section */}
          {/* ======= Why Us Section ======= */}
          <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">
              <div className="row">
                <div
                  className="col-lg-5 align-items-stretch video-box"
                  style={{ backgroundImage: 'url("assets/img/why-us.jpg")' }}
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <a
                    href="https://www.youtube.com/watch?v=JDB6uRTRdTc"
                    className="venobox play-btn mb-4"
                    data-vbtype="video"
                    data-autoplay="true"
                  />
                </div>
                <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                  <div className="content">
                    <h3>
                      Our Experience and expertise brings your safety <br />
                      <strong>We Always Ensure Your Safety First! </strong>
                    </h3>
                    <p>
                      #Started in 2008 with Rent a Car services(RAC), expanded
                      to provide Fleet Management services (FMS) in
                      multinational companies .Set of fleet operated is around
                      800 vehicles for various clients .Providing services in
                      major cities across India.
                    </p>
                  </div>
                  <div className="accordion-list">
                    <ul>
                      <li>
                        <a
                          data-bs-toggle="collapse"
                          className="collapse"
                          data-bs-target="#accordion-list-1"
                        >
                          <span>01</span> How do you ensure that we are safe to
                          take a ride ?{' '}
                          <i className="bx bx-chevron-down icon-show" />
                          <i className="bx bx-chevron-up icon-close" />
                        </a>
                        <div
                          id="accordion-list-1"
                          className="collapse show"
                          data-bs-parent=".accordion-list"
                        >
                          <p>
                            All drivers are recruited through references to
                            avoid uncertainties,Ensure drivers are experienced
                            and trained in basic vehicle maintenance.
                          </p>
                        </div>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-list-2"
                          className="collapsed"
                        >
                          <span>02</span> How do you Ensure and monitor safety
                          measures?{' '}
                          <i className="bx bx-chevron-down icon-show" />
                          <i className="bx bx-chevron-up icon-close" />
                        </a>
                        <div
                          id="accordion-list-2"
                          className="collapse"
                          data-bs-parent=".accordion-list"
                        >
                          <p>
                            Our team ensures that drivers get enough rest and is
                            attentive while driving on duty.Both owned and
                            attached vehicles are subject to periodic checks
                            Safety kits such as first aid and fire extinguishers
                            are replenished regularly.
                          </p>
                        </div>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-list-3"
                          className="collapsed"
                        >
                          <span>03</span> How do we check the Trip status and
                          location?{' '}
                          <i className="bx bx-chevron-down icon-show" />
                          <i className="bx bx-chevron-up icon-close" />
                        </a>
                        <div
                          id="accordion-list-3"
                          className="collapse"
                          data-bs-parent=".accordion-list"
                        >
                          <p>
                            All vehicles are equipped with necessary tool kit
                            and spare consumables All Vehicles monitored by GPS
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Why Us Section */}
          {/* ======= Services Section ======= */}
          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Services</h2>
                <p>Check our Services</p>
              </div>
              <div className="row">
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bxl-dribbble" />
                    </div>
                    <h4>
                      <a href>Rent a Car services </a>
                    </h4>
                    <p>
                      We provide all seating capacity and premium cars for local
                      trip, picnic,
                      <br /> school outings, family gatherings.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-file" />
                    </div>
                    <h4>
                      <a href>Fleet Management services</a>
                    </h4>
                    <p>
                      800+ fleet to manage corporate employee movements 24/7
                      operations with help-desk to cater to their needs.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-tachometer" />
                    </div>
                    <h4>
                      <a href>Coach Services-Luxury</a>
                    </h4>
                    <p>
                      {/* Maxi cabs and buses with AC, audio and video features for
                      events, team outing and outstation trips. */}
                      luxury seats with push back options, equipped with LCD tv
                      & Dolby stereo speakers for unlimited entertainment
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={100}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-world" />
                    </div>
                    <h4>
                      <a href>Maxi cabs and buses of all seating capacities.</a>
                    </h4>
                    <p>
                      #Staff Trained in behavior, # Implant facilities offered,
                      #Well maintained statutory, safety compliance,
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={200}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-slideshow" />
                    </div>
                    <h4>
                      <a href>Airport Transfers </a>
                    </h4>
                    <p>
                      # Provide vehicle and driver’s records to clients,
                      #Training and periodic reorientation for chauffers by Rtd
                      RTO inspectors.
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                  data-aos="zoom-in"
                  data-aos-delay={300}
                >
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bx bx-arch" />
                    </div>
                    <h4>
                      <a href>Expat services and Monthly lease options</a>
                    </h4>
                    <p>
                      # Customer specific service levels, # SMS alert to guest
                      for confirmation and pickup details 24/7 GPS Monitoring, #
                      customized application based services for routing,
                      #trained drivers to cater to all expat requirement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* End Services Section */}

          {/* ======= Testimonials Section ======= */}
          {/* <section id="testimonials" className="testimonials section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Testimonials</h2>
                <p>Testimonials</p>
              </div> */}

          {/* START Testimonials Working */}
          {/* <div
                className="testimonials-slider swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div
                  className="swiper-wrapper"
                  id="swiper-wrapper-e47202ad7a1c1fc2"
                  aria-live="off"
                  style={{
                    transform:
                      counter === 0
                        ? 'translate3d(-1974px, 0px, 0px)'
                        : counter === 1
                        ? 'translate3d(-2632px, 0px, 0px)'
                        : counter === 2
                        ? 'translate3d(-3290px, 0px, 0px)'
                        : counter === 3
                        ? 'translate3d(-3948px, 0px, 0px)'
                        : 'translate3d(-4606px, 0px, 0px)',
                    transitionDuration: '800ms',
                  }}
                >
                  <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-duplicate'
                        : counter === 1
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next'
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev'
                        : 'swiper-slide swiper-slide-duplicate'
                    }
                    data-swiper-slide-index={3}
                    role="group"
                    aria-label="4 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-4.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Matt Brandon</h3>
                        <h4>Freelancer</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Fugiat enim eram quae cillum dolore dolor amet nulla
                          culpa multos export minim fugiat minim velit minim
                          dolor enim duis veniam ipsum anim magna sunt elit fore
                          quem dolore labore illum veniam.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-duplicate '
                        : counter === 1
                        ? 'swiper-slide swiper-slide-duplicate '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active'
                        : 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev'
                    }
                    data-swiper-slide-index={4}
                    role="group"
                    aria-label="5 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-5.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>John Larson</h3>
                        <h4>Entrepreneur</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Quis quorum aliqua sint quem legam fore sunt eram
                          irure aliqua veniam tempor noster veniam enim culpa
                          labore duis sunt culpa nulla illum cillum fugiat legam
                          esse veniam culpa fore nisi cillum quid.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      counter === 0
                        ? 'swiper-slide '
                        : counter === 1
                        ? 'swiper-slide swiper-slide-duplicate-next '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate-active'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-prev'
                        : 'swiper-slide'
                    }
                    data-swiper-slide-index={0}
                    role="group"
                    aria-label="1 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-1.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Saul Goodman</h3>
                        <h4>Ceo &amp; Founder</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Proin iaculis purus consequat sem cure digni ssim
                          donec porttitora entum suscipit rhoncus. Accusantium
                          quam, ultricies eget id, aliquam eget nibh et. Maecen
                          aliquam, risus at semper.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div> */}
          {/* End testimonial item */}
          {/* <div
                    className={
                      counter === 0
                        ? 'swiper-slide '
                        : counter === 1
                        ? 'swiper-slide  '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate-next'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-active'
                        : 'swiper-slide swiper-slide-prev'
                    }
                    data-swiper-slide-index={1}
                    role="group"
                    aria-label="2 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-2.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Sara Wilsson</h3>
                        <h4>Designer</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Export tempor illum tamen malis malis eram quae irure
                          esse labore quem cillum quid cillum eram malis quorum
                          velit fore eram velit sunt aliqua noster fugiat irure
                          amet legam anim culpa.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div> */}
          {/* End testimonial item */}
          {/* <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-prev'
                        : counter === 1
                        ? 'swiper-slide  '
                        : counter === 2
                        ? 'swiper-slide '
                        : counter === 3
                        ? 'swiper-slide swiper-slide-next'
                        : 'swiper-slide swiper-slide-active'
                    }
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-3.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Jena Karlis</h3>
                        <h4>Store Owner</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Enim nisi quem export duis labore cillum quae magna
                          enim sint quorum nulla quem veniam duis minim tempor
                          labore quem eram duis noster aute amet eram fore quis
                          sint minim.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div> */}
          {/* End testimonial item */}
          {/* <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-active'
                        : counter === 1
                        ? 'swiper-slide swiper-slide-prev '
                        : counter === 2
                        ? 'swiper-slide '
                        : counter === 3
                        ? 'swiper-slide '
                        : 'swiper-slide swiper-slide-next'
                    }
                    data-swiper-slide-index={3}
                    role="group"
                    aria-label="4 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-4.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Matt Brandon</h3>
                        <h4>Freelancer</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Fugiat enim eram quae cillum dolore dolor amet nulla
                          culpa multos export minim fugiat minim velit minim
                          dolor enim duis veniam ipsum anim magna sunt elit fore
                          quem dolore labore illum veniam.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div> */}
          {/* End testimonial item */}
          {/* <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-next'
                        : counter === 1
                        ? 'swiper-slide swiper-slide-active '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-prev'
                        : counter === 3
                        ? 'swiper-slide '
                        : 'swiper-slide '
                    }
                    data-swiper-slide-index={4}
                    role="group"
                    aria-label="5 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-5.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>John Larson</h3>
                        <h4>Entrepreneur</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Quis quorum aliqua sint quem legam fore sunt eram
                          irure aliqua veniam tempor noster veniam enim culpa
                          labore duis sunt culpa nulla illum cillum fugiat legam
                          esse veniam culpa fore nisi cillum quid.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div> */}
          {/* End testimonial item */}
          {/* <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-duplicate '
                        : counter === 1
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-next '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-active'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev'
                        : 'swiper-slide swiper-slide-duplicate'
                    }
                    data-swiper-slide-index={0}
                    role="group"
                    aria-label="1 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-1.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Saul Goodman</h3>
                        <h4>Ceo &amp; Founder</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Proin iaculis purus consequat sem cure digni ssim
                          donec porttitora entum suscipit rhoncus. Accusantium
                          quam, ultricies eget id, aliquam eget nibh et. Maecen
                          aliquam, risus at semper.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      counter === 0
                        ? 'swiper-slide swiper-slide-duplicate '
                        : counter === 1
                        ? 'swiper-slide swiper-slide-duplicate  '
                        : counter === 2
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-next'
                        : counter === 3
                        ? 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active'
                        : 'swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev'
                    }
                    data-swiper-slide-index={1}
                    role="group"
                    aria-label="2 / 5"
                    style={{ width: '638px', marginRight: '20px' }}
                  >
                    <div className="testimonial-wrap">
                      <div className="testimonial-item">
                        <img
                          src="assets/img/testimonials/testimonials-2.jpg"
                          className="testimonial-img"
                          alt=""
                        />
                        <h3>Sara Wilsson</h3>
                        <h4>Designer</h4>
                        <p>
                          <i className="bx bxs-quote-alt-left quote-icon-left" />
                          Export tempor illum tamen malis malis eram quae irure
                          esse labore quem cillum quid cillum eram malis quorum
                          velit fore eram velit sunt aliqua noster fugiat irure
                          amet legam anim culpa.
                          <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
                  <span
                    className={
                      counter === 0
                        ? 'swiper-pagination-bullet swiper-pagination-bullet-active'
                        : ' swiper-pagination-bullet'
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 1"
                  />
                  <span
                    className={
                      counter === 1
                        ? 'swiper-pagination-bullet swiper-pagination-bullet-active'
                        : ' swiper-pagination-bullet'
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 2"
                  />
                  <span
                    className={
                      counter === 2
                        ? 'swiper-pagination-bullet swiper-pagination-bullet-active'
                        : ' swiper-pagination-bullet'
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 3"
                    aria-current="true"
                  />
                  <span
                    className={
                      counter === 3
                        ? 'swiper-pagination-bullet swiper-pagination-bullet-active'
                        : ' swiper-pagination-bullet'
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 4"
                  />
                  <span
                    className={
                      counter === 4
                        ? 'swiper-pagination-bullet swiper-pagination-bullet-active'
                        : ' swiper-pagination-bullet'
                    }
                    tabIndex={0}
                    role="button"
                    aria-label="Go to slide 5"
                  />
                </div>
                <span
                  className="swiper-notification"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div> */}

          {/* END Testimonials Working */}
          {/* </div>
          </section> */}
          {/* End Testimonials Section */}
          {/* ======= Cta Section ======= */}
          <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
              <div className="text-center">
                <h3>Call To Action</h3>
                <p>
                  {' '}
                  #Trained staff to understand clients’ need , Trained
                  drivers,24/7 Support,Competitive rates. Top priority given to
                  Safety standards.,Customer focused solutions to areas in
                  transportation.
                </p>
                <a className="cta-btn" href="#">
                  Call To Action
                </a>
              </div>
            </div>
          </section>
          {/* End Cta Section */}
          {/* ======= Portfolio Section ======= */}
          <section id="portfolio" className="portfolio">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Portfolio</h2>
                <p>Check our Portfolio</p>
              </div>
              <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-12 d-flex justify-content-center">
                  <ul id="portfolio-flters">
                    <li data-filter="*" className="filter-active">
                      All
                    </li>
                    <li data-filter=".filter-app">Cab</li>
                    <li data-filter=".filter-card">Bus</li>
                    <li data-filter=".filter-web">Suv</li>
                    <li data-filter=".filter-pre">Premium</li>
                  </ul>
                </div>
              </div>
              <div
                className="row portfolio-container"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-1.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 1</h4>
                    <p>App</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-1.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="App 1"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-2.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Web 3</h4>
                    <p>Web</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-2.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Web 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-3.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 2</h4>
                    <p>App</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-3.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="App 2"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-4.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 2</h4>
                    <p>Card</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-4.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Card 2"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-5.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Web 2</h4>
                    <p>Web</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-5.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Web 2"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-6.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>App 3</h4>
                    <p>App</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-6.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="App 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-7.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 1</h4>
                    <p>Card</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-7.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Card 1"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-8.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 3</h4>
                    <p>Card</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-8.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Card 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-9.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>SUV</h4>
                    <p>Innova Crista 7 seater capacity.</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-9.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Web 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-pre">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-10.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 1</h4>
                    <p>Card</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-10.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Card 1"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-pre">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-11.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Card 3</h4>
                    <p>Card</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-11.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Card 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-pre">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      'assets/img/portfolio/portfolio-12.jpg'
                    }
                    className="img-fluid-all"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>Premium</h4>
                    <p>Innova Crista 7 seater capacity.</p>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        'assets/img/portfolio/portfolio-12.jpg'
                      }
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      title="Web 3"
                    >
                      <i className="bx bx-plus" />
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="bx bx-link" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Portfolio Section */}
          {/* ======= Team Section ======= */}
          {/* <section id="team" className="team section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Team</h2>
                <p>Check our Team</p>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div
                    className="member"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/team/team-1.jpg'
                      }
                      className="img-fluid-all"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Walter White</h4>
                        <span>Chief Executive Officer</span>
                      </div>
                      <div className="social">
                        <a href>
                          <i className="bi bi-twitter" />
                        </a>
                        <a href>
                          <i className="bi bi-facebook" />
                        </a>
                        <a href>
                          <i className="bi bi-instagram" />
                        </a>
                        <a href>
                          <i className="bi bi-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="member"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/team/team-2.jpg'
                      }
                      className="img-fluid-all"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                      </div>
                      <div className="social">
                        <a href>
                          <i className="bi bi-twitter" />
                        </a>
                        <a href>
                          <i className="bi bi-facebook" />
                        </a>
                        <a href>
                          <i className="bi bi-instagram" />
                        </a>
                        <a href>
                          <i className="bi bi-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-wow-delay="0.2s"
                >
                  <div
                    className="member"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/team/team-3.jpg'
                      }
                      className="img-fluid-all"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>William Anderson</h4>
                        <span>CTO</span>
                      </div>
                      <div className="social">
                        <a href>
                          <i className="bi bi-twitter" />
                        </a>
                        <a href>
                          <i className="bi bi-facebook" />
                        </a>
                        <a href>
                          <i className="bi bi-instagram" />
                        </a>
                        <a href>
                          <i className="bi bi-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-4 col-md-6"
                  data-wow-delay="0.3s"
                >
                  <div
                    className="member"
                    data-aos="zoom-in"
                    data-aos-delay={400}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/team/team-4.jpg'
                      }
                      className="img-fluid-all"
                      alt=""
                    />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                      </div>
                      <div className="social">
                        <a href>
                          <i className="bi bi-twitter" />
                        </a>
                        <a href>
                          <i className="bi bi-facebook" />
                        </a>
                        <a href>
                          <i className="bi bi-instagram" />
                        </a>
                        <a href>
                          <i className="bi bi-linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* End Team Section */}
          {/* ======= Pricing Section ======= */}
          {/* <section id="pricing" className="pricing">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Pricing</h2>
                <p>Our Competing Prices</p>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div
                    className="box featured"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <h3>Free</h3>
                    <h4>
                      $0<span>per month</span>
                    </h4>
                    <ul>
                      <li>
                        <i className="bx bx-check" /> Quam adipiscing vitae
                        proin
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nec feugiat nisl pretium
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nulla at volutpat diam
                        uteera
                      </li>
                      <li className="na">
                        <i className="bx bx-x" />{' '}
                        <span>Pharetra massa massa ultricies</span>
                      </li>
                      <li className="na">
                        <i className="bx bx-x" />{' '}
                        <span>Massa ultricies mi quis hendrerit</span>
                      </li>
                    </ul>
                    <a href="#" className="get-started-btn">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="box featured"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <h3>Business</h3>
                    <h4>
                      $29<span>per month</span>
                    </h4>
                    <ul>
                      <li>
                        <i className="bx bx-check" /> Quam adipiscing vitae
                        proin
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nec feugiat nisl pretium
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nulla at volutpat diam
                        uteera
                      </li>
                      <li>
                        <i className="bx bx-check" /> Pharetra massa massa
                        ultricies
                      </li>
                      <li>
                        <i className="bx bx-check" /> Massa ultricies mi quis
                        hendrerit
                      </li>
                    </ul>
                    <a href="#" className="get-started-btn">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="box featured"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <h3>Developer</h3>
                    <h4>
                      $49<span>per month</span>
                    </h4>
                    <ul>
                      <li>
                        <i className="bx bx-check" /> Quam adipiscing vitae
                        proin
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nec feugiat nisl pretium
                      </li>
                      <li>
                        <i className="bx bx-check" /> Nulla at volutpat diam
                        uteera
                      </li>
                      <li>
                        <i className="bx bx-check" /> Pharetra massa massa
                        ultricies
                      </li>
                      <li>
                        <i className="bx bx-check" /> Massa ultricies mi quis
                        hendrerit
                      </li>
                    </ul>
                    <a href="#" className="get-started-btn">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* End Pricing Section */}
          {/* ======= Frequently Asked Questions Section ======= */}
          {/* <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>F.A.Q</h2>
                <p>Frequently Asked Questions</p>
              </div>
              <div
                className="row faq-item d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="col-lg-5">
                  <i className="bx bx-help-circle" />
                  <h4>Non consectetur a erat nam at lectus urna duis?</h4>
                </div>
                <div className="col-lg-7">
                  <p>
                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
                    volutpat lacus laoreet non curabitur gravida. Venenatis
                    lectus magna fringilla urna porttitor rhoncus dolor purus
                    non.
                  </p>
                </div>
              </div> */}
          {/* End F.A.Q Item*/}
          {/* <div
                className="row faq-item d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="col-lg-5">
                  <i className="bx bx-help-circle" />
                  <h4>
                    Feugiat scelerisque varius morbi enim nunc faucibus a
                    pellentesque?
                  </h4>
                </div>
                <div className="col-lg-7">
                  <p>
                    Dolor sit amet consectetur adipiscing elit pellentesque
                    habitant morbi. Id interdum velit laoreet id donec ultrices.
                    Fringilla phasellus faucibus scelerisque eleifend donec
                    pretium. Est pellentesque elit ullamcorper dignissim.
                  </p>
                </div>
              </div> */}
          {/* End F.A.Q Item*/}
          {/* <div
                className="row faq-item d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="col-lg-5">
                  <i className="bx bx-help-circle" />
                  <h4>
                    Dolor sit amet consectetur adipiscing elit pellentesque
                    habitant morbi?
                  </h4>
                </div>
                <div className="col-lg-7">
                  <p>
                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                    sagittis orci. Faucibus pulvinar elementum integer enim. Sem
                    nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
                    pellentesque eu tincidunt. Lectus urna duis convallis
                    convallis tellus.
                  </p>
                </div>
              </div> */}
          {/* End F.A.Q Item*/}
          {/* <div
                className="row faq-item d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="col-lg-5">
                  <i className="bx bx-help-circle" />
                  <h4>
                    Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla?
                  </h4>
                </div>
                <div className="col-lg-7">
                  <p>
                    Aperiam itaque sit optio et deleniti eos nihil quidem
                    cumque. Voluptas dolorum accusantium sunt sit enim.
                    Provident consequuntur quam aut reiciendis qui rerum dolorem
                    sit odio. Repellat assumenda soluta sunt pariatur error
                    doloribus fuga.
                  </p>
                </div>
              </div> */}
          {/* End F.A.Q Item*/}
          {/* <div
                className="row faq-item d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="col-lg-5">
                  <i className="bx bx-help-circle" />
                  <h4>
                    Tempus quam pellentesque nec nam aliquam sem et tortor
                    consequat?
                  </h4>
                </div>
                <div className="col-lg-7">
                  <p>
                    Molestie a iaculis at erat pellentesque adipiscing commodo.
                    Dignissim suspendisse in est ante in. Nunc vel risus commodo
                    viverra maecenas accumsan. Sit amet nisl suscipit adipiscing
                    bibendum est. Purus gravida quis blandit turpis cursus in
                  </p>
                </div>
              </div> */}
          {/* End F.A.Q Item*/}
          {/* </div>
          </section> */}
          {/* End Frequently Asked Questions Section */}
          {/* ======= Contact Section ======= */}
          <section id="contact" className="contact section-bg">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Contact</h2>
                <p>Contact Us</p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="info-box">
                        <i className="bx bx-map" />
                        <h3>Our Address</h3>
                        <p>
                          #138, 2nd Main, East of NGEF, Kasturinagar, Bangalore
                          560043
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="info-box mt-4">
                        <i className="bx bx-envelope" />
                        <h3>Email Us</h3>
                        <p>
                          travel@transprofessionals.com
                          <br />
                          {/* info@transprofessionals.com */}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="info-box mt-4">
                        <i className="bx bx-phone-call" />
                        <h3>Call Us</h3>
                        <p>
                          +91 9060019000
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6">
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    className="php-email-form"
                  >
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows={5}
                        placeholder="Message"
                        required
                        defaultValue={''}
                      />
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message" />
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div> */}
                <div className="col-lg-6">
                  <form
                    onSubmit={handleSubmit}
                    role="form"
                    className="php-email-form"
                    // className="email-form"
                  >
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your Name"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Your Email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        name="message"
                        rows={5}
                        placeholder="Message"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="my-3">
                      {status === 'loading' && (
                        <div className="loading">Loading</div>
                      )}
                      {status === 'error' && (
                        <div className="error-message">
                          Error sending message
                        </div>
                      )}
                      {status === 'success' && (
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      )}
                    </div>
                    <div className="text-center">
                      <button type="submit">Send Message</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
          {/* End Contact Section */}
        </main>
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-info">
                    <h2>
                      <a href="/" className="logo">
                        <img
                          src="assets/img/logo-no-bg.png"
                          alt=""
                          className="img-fluid-all"
                        />
                      </a>
                    </h2>
                    <p className="pb-3">
                      <em>
                        We always Deliver Safety. Our Customer Centric approach
                        achive all safety goals. We operate with Service
                        Orientated mindset.
                      </em>
                    </p>
                    <p>
                      #138, 2nd Main, East of NGEF, Kasturinagar, <br />
                      Bangalore 560043
                      <br />
                      <br />
                      <strong>Phone:</strong> +91 9060019000
                      <br />
                      <strong>Email:</strong> travel@transprofessionals.com
                      <br />
                    </p>
                    <div className="social-links mt-3">
                      <a href="#" className="twitter">
                        <i className="bx bxl-twitter" />
                      </a>
                      <a href="#" className="facebook">
                        <i className="bx bxl-facebook" />
                      </a>
                      <a href="#" className="instagram">
                        <i className="bx bxl-instagram" />
                      </a>
                      <a href="#" className="google-plus">
                        <i className="bx bxl-skype" />
                      </a>
                      <a href="#" className="linkedin">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#about">About us</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Terms of service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Book a Cab</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Customer service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Package enquery </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Feed Back</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>
                    Please provide your email address for our offers and package
                    details.
                  </p>
                  <form action method="post">
                    <input type="email" name="email" />
                    <input type="submit" defaultValue="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              © Copyright{' '}
              <strong>
                <span>Transprofessionals Pvt Ltd</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/multi-responsive-bootstrap-template/ */}
              Designed by{' '}
              <a href="https://transprofessionals.com/">TransPro team</a>
            </div>
          </div>
        </footer>
        {/* End Footer */}
        {/* <div id="preloader" /> */}
        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
        {/* Vendor JS Files */}
        {/* Template Main JS File */}
      </div>
    </div>
  );
};

export default Home;
