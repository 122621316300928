import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import 'bootstrap/dist/css/bootstrap.min.css';

const packages = [
  // Array containing package details (replace with your actual package data)

  {
    id: 1,
    name: 'Package trip to Kerala',
    image: require('../assets/img/package-2.jpg'),
    description:
      'Embark on a mesmerizing journey through Kerala with our enticing package trip! Discover the enchanting backwaters as you cruise along serene canals, rejuvenate with Ayurvedic treatments, and witness the beauty of lush tea plantations. Immerse yourself in the rich heritage, savor delectable cuisine, and create unforgettable experiences in the land of tranquil beauty.',
  },
  {
    id: 2,
    name: 'Package tour to Jog Falls',
    image: require('../assets/img/package-3.jpg'),
    description:
      'Discover the breathtaking beauty of Jog Falls with our captivating package trip! Marvel at the magnificence of Indias second-highest waterfall as it plunges down a rugged cliff. Immerse yourself in the serene surroundings, explore verdant forests, and partake in exciting activities like trekking and photography. Experience the awe-inspiring grandeur of Jog Falls and create everlasting memories.',
  },

  {
    id: 5,
    name: 'Trip to Madikari',
    image: require('../assets/img/package-5.jpg'),
    description:
      'Experience the captivating charm of Madikari with our enticing package trip! Delight in the picturesque beauty of the Kaveri River as you embark on a serene river cruise. Immerse yourself in the rich wildlife, explore lush forests, and indulge in thrilling activities like bird watching and nature walks. Create unforgettable memories in the tranquil oasis of Madikari, where natures splendor awaits.',
  },
  {
    id: 6,
    name: 'Exploring Wayanad',
    image: require('../assets/img/package-6.jpg'),
    description:
      'Explore the lush and tranquil beauty of Wayanad with our enticing package! Immerse yourself in the serene landscapes of Western Ghats, trek through dense forests, and encounter wildlife in its natural habitat. Discover hidden waterfalls, spice plantations, and ancient caves, making memories that blend adventure with natures serenity.',
  },
  {
    id: 7,
    name: 'Mysore and Ooty',
    image: require('../assets/img/package-7.jpg'),
    description:
      'Experience the best of South Indias charm with our Mysore and Ooty package! Explore the opulent heritage of Mysore with its magnificent palaces and vibrant markets. Then, venture into the Nilgiri Hills to discover the misty landscapes and botanical wonders of Ooty, where scenic tea gardens and serene lakes await. This journey combines culture, history, and nature for an unforgettable South Indian adventure.',
  },
  {
    id: 8,
    name: 'Bangalore City',
    image: require('../assets/img/package-8.jpg'),
    description:
      'Discover the dynamic heart of Indias Silicon Valley with our Bangalore package! Immerse yourself in the citys vibrant tech scene, explore lush parks and gardens, and savor delectable street food. From the iconic Vidhana Soudha to the bustling markets of Brigade Road, Bangalore offers a perfect blend of modernity and tradition for an unforgettable urban escape.',
  },
  {
    id: 9,
    name: 'White Water Rafting in Dandeli',
    image: require('../assets/img/package-9.jpg'),
    description:
      'Experience the untamed beauty of Dandeli with our adventure-packed package! Dive into thrilling water sports along the Kali River, explore dense forests teeming with wildlife, and unwind in eco-friendly jungle resorts. From white-water rafting to jungle safaris, Dandeli promises an adrenaline-pumping retreat in the heart of nature.',
  },
  {
    id: 10,
    name: 'Mysore Dasara',
    image: require('../assets/img/package-10.jpg'),
    description:
      'Celebrate the grandeur of Mysore Dasara with our exclusive package! Witness the city come alive with vibrant processions, dazzling light displays, and cultural extravaganzas during this iconic festival. Immerse yourself in the rich heritage, visit the resplendent Mysore Palace, and partake in the joyous festivities that make Dasara a unique and unforgettable experience.',
  },
  {
    id: 11,
    name: 'Muthanga Wildlife Sanctuary!',
    image: require('../assets/img/package-11.jpg'),
    description:
      'Embark on a thrilling safari adventure at Muthanga Wildlife Sanctuary! Explore the lush greenery of this biodiverse haven in Wayanad, Kerala, as you encounter majestic elephants, elusive tigers, and a myriad of exotic wildlife species in their natural habitat. Immerse yourself in the serenity of nature while experiencing the thrill of wildlife spotting, making memories to last a lifetime.',
  },
  {
    id: 12,
    name: 'Badami-Aihole-Pattadakal',
    image: require('../assets/img/package-12.jpg'),
    description:
      'Explore the rich architectural heritage of Karnataka with our Badami-Aihole-Pattadakal package! Wander through the ancient rock-cut caves of Badami, admire the exquisite temples of Aihole, and marvel at the UNESCO World Heritage sites of Pattadakal. This journey takes you through centuries of history and artistry, offering a glimpse into the magnificent Chalukyan dynastys legacy.',
  },
  {
    id: 13,
    name: 'Mysore coorg and waynad',
    image: require('../assets/img/package-13.jpg'),
    description:
      'Embark on a South Indian odyssey with our Mysore, Coorg, and Wayanad package! Immerse yourself in the regal opulence of Mysore, savor the aromatic coffee plantations of Coorg, and explore the lush landscapes of Wayanad. From majestic palaces to misty hills and serene forests, this journey combines culture, nature, and relaxation for an unforgettable experience.',
  },
  {
    id: 14,
    name: 'Hampi ',
    image: require('../assets/img/package-14.jpg'),
    description:
      'Unearth the ancient wonders of Hampi with our captivating package! Explore the mesmerizing ruins of this UNESCO World Heritage Site, where history comes alive amidst impressive stone temples, bouldered landscapes, and the mystical Tungabhadra River. Immerse yourself in the stories of a bygone era and experience the unique charm of this historical treasure trove.',
  },
  {
    id: 3,
    name: 'Package Trip to Goa',
    image: require('../assets/img/package-1.jpg'),
    description:
      'Experience the ultimate beach getaway with our package trip to Goa! Immerse yourself in the vibrant culture, indulge in delicious seafood, and unwind on pristine sandy shores. Explore ancient temples, enjoy thrilling water sports, and create memories that will last a lifetime in this tropical paradise.',
  },
  {
    id: 4,
    name: 'Journey to Kanyakumari',
    image: require('../assets/img/package-4.jpg'),
    description:
      'Embark on a remarkable journey to Kanyakumari with our all-inclusive package! Witness the breathtaking convergence of the Indian Ocean, Arabian Sea, and Bay of Bengal at the southernmost tip of India. Immerse yourself in the cultural richness, visit iconic landmarks like the Vivekananda Rock Memorial and Thiruvalluvar Statue, and soak in stunning sunsets against the backdrop of this coastal paradise.',
  },
  // Add more package objects as needed
];

const Booking = () => {
  console.log('##################### You have clicked  Package ##############');
  const [menu, setMenu] = useState(true);
  const [dropMenu, setDropMenu] = useState(true);
  const [deepMenu, setDeepMenu] = useState(true);
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);

  const [activeTab, setActiveTab] = useState('airport');

  const [phone, setPhone] = useState('');
  const [terminal, setTerminal] = useState('');
  const [pickUpDrop, setPickUpDrop] = useState('');
  const [pickupPoint, setPickupPoint] = useState('');
  const [dropPoint, setDropPoint] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [packageType, setPackageType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 6 second!', counter);

      console.log('##########ADD#############');
      // setCounter(prev => prev + 1);
      setCounter((counter) => counter + 1);
      setCount((count) => count + 1);
      // handleCarouselRight();
    }, 6000);
    return () => clearInterval(interval);
  }, []);
  console.log('#####################', counter, '##############');
  if (counter === 5) {
    setCounter(0);
  }

  console.log('#####################', count, '##############');
  if (count === 5) {
    setCount(0);
  }

  function handleClick(e) {
    e.preventDefault();
    setMenu(!menu);

    console.log('#####################clicked ##############');
  }

  function handleDropdown(e) {
    e.preventDefault();
    setDropMenu(!dropMenu);
    console.log('#####################clicked ##############');
  }

  function handleDeepdown(e) {
    e.preventDefault();
    setDeepMenu(!deepMenu);
    console.log('#####################clicked ##############');
  }

  function handleCarouselLeft(e) {
    e.preventDefault();
    if (count === 0) {
      setCount(4);
    } else {
      setCount(count - 1);
    }

    console.log('#####################clicked ##############');
  }

  function handleCarouselRight(e) {
    e.preventDefault();
    if (count === 5) {
      setCount(0);
    } else {
      setCount(count + 1);
    }

    console.log('#####################clicked ##############');
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  //   const handleTabClick = (tab) => {
  //     setActiveTab(tab);
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Handle form submission logic here
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('############### TRY SENDING MAIL @@@@@@@@@@@@');
      console.log(
        '###############',
        name,
        email,
        subject,
        message,
        '@@@@@@@@@@@@'
      );
      const response = await axios.post('/send-email', {
        name,
        email,
        subject,
        message,
      });

      if (response.status === 200) {
        setStatus('success');
        // Reset form fields
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  //   const MyContainer = ({ className, children }) => {
  //     return (
  //       <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
  //         <CalendarContainer className={className}>
  //           <div style={{ background: "#f0f0f0" }}>
  //             What is your favorite day?
  //           </div>
  //           <div style={{ position: "relative" }}>{children}</div>
  //         </CalendarContainer>
  //       </div>
  //     );
  //   };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, onChange, placeholder }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
      ></input>
    )
  );

  return (
    <div>
      <div>
        {' '}
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Multi Bootstrap Template - Index</title>
        <meta content name="description" />
        <meta content name="keywords" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          href={process.env.PUBLIC_URL + 'assets/img/favicon.png'}
          rel="icon"
        />
        <link
          href={process.env.PUBLIC_URL + 'assets/img/logo.png'}
          // href={process.env.PUBLIC_URL + 'assets/img/apple-touch-icon.png'}
          rel="apple-touch-icon"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/animate.css/animate.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/glightbox/css/glightbox.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="assets/vendor/swiper/swiper-bundle.min.css"
          rel="stylesheet"
        />
        <link href="assets/css/style.css" rel="stylesheet" />
        <header-top id="header" className="fixed-top">
          <div className="container d-flex align-items-center justify-content-between sticked">
            {/* <h1 className="logo">
              <a href="/">Multi</a>
            </h1> */}
            {/* Uncomment below if you prefer to use an image logo */}
            <a href="/" className="logo">
              <img src="assets/img/logo.png" alt="" className="img-fluid" />
            </a>
            <nav id="navbar" className={menu ? 'navbar' : 'navbar-mobile'}>
              {/* <nav id="navbar" className="navbar"> */}
              <ul>
                <li>
                  <a className="nav-link scrollto " href="/">
                    {/* <a className="nav-link scrollto active" href="/"> */}
                    Home
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="/#about">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="/#services">
                    Services
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/#portfolio">
                    Fleet
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Packages">
                    Package
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Booking">
                    Booking
                  </a>
                </li>
                {/* <li>
                  <a className="nav-link scrollto" href="#team">
                    Team
                  </a>
                </li> */}
                {/* <li className="dropdown">
                  {/* <li className={dropMenu}> */}
                {/* <a href="#">
                    <span>Our Offerings</span>{' '}
                    <i
                      className="bi bi-chevron-down"
                      onClick={handleDropdown}
                    />
                  </a>
                  <ul className={dropMenu ? '' : 'dropdown-active'}>
                    <li>
                      <a href="#">Book a Cab</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">
                        <span>Customer service</span>{' '}
                        <i
                          className="bi bi-chevron-right"
                          onClick={handleDeepdown}
                        />
                      </a>
                      <ul className={deepMenu ? '' : 'dropdown-active'}>
                        <li>
                          <a href="#">@Bangalore Office</a>
                        </li>
                        <li>
                          <a href="#">@Booking Related Query</a>
                        </li>
                        <li>
                          <a href="#">@Billing Query</a>
                        </li>
                        <li>
                          <a href="#">@Package Query</a>
                        </li>
                        <li>
                          <a href="#">@Make a Payment </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Package enquiry</a>
                    </li>
                    <li>
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <a href="#">Feed Back</a>
                    </li>
                  </ul> */}
                {/* </li> */}
                {/* <li>
                  <a className="nav-link scrollto" href="#contact">
                    Contact
                  </a>
                </li> */}
                {/* <li>
                  <a className="getstarted scrollto" href="#about">
                    Get Started
                  </a>
                </li> */}
              </ul>
              <i
                className={
                  menu
                    ? 'bi bi-list mobile-nav-toggle'
                    : 'bi  mobile-nav-toggle bi-x'
                }
                onClick={handleClick}
              />
              {/* <i className="bi bi-list mobile-nav-toggle" /> */}
            </nav>
            <div>
              {/* <i className="transprofessionals">
                <p className="blankLineP">{`Transprofessionals `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              {/* <i className="toursAndTravelsPvtLtd">
                <p className="blankLineP">{`Tours and Travels Pvt Ltd `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              <div className="transprofessionals">Transprofessionals</div>
              <div className="toursAndTravelsPvtLtd">
                Tours and Travels Pvt Ltd{' '}
              </div>
            </div>

            {/* .navbar */}
          </div>
        </header-top>
        <section className="banner-2" id="top">
          <div className="container" data-aos="">
            <section id="services" className="services">
              <div className="container" data-aos="fade-up">
                <div className="section-title">
                  <h20>Book Your Package</h20>
                  <p>Available Packages and Offers</p>
                </div>
                <div className="row">
                  <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <a className="row a-1" href="/Package01">
                      <div className="icon-box-1">
                        {/* <div className="icon">
                        <i className="bx bxl-dribbble" />
                      </div> */}
                        <h2>
                          <a href="/Package01" className="logo">
                            <img
                              src="assets/img/Package1-02.jpg"
                              alt=""
                              className="img-fluid-1"
                            />
                          </a>
                        </h2>
                        <h4>
                          <a href>MYSORE & OOTY TOUR (3N/4D)</a>
                        </h4>
                        <p className="p-p">
                          <h5>Bangalore-Mysore-Ooty</h5>
                          <br /> DAY 1 : BANGALORE TO MYSORE
                          <br /> DAY 2 : MYSORE TO OOTY
                          <br /> DAY 3 : OOTY – COONOOR - OOTY
                          <br /> DAY 4 : ENROUTE BANGALORE
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <a className="a-1" href="/Package02">
                      <div className="icon-box-1">
                        {/* <div className="icon">
                        <i className="bx bxl-dribbble" />
                      </div> */}
                        <h2>
                          <a href="/Package02" className="logo">
                            <img
                              src="assets/img/Package2-02.jpg"
                              alt=""
                              className="img-fluid-1"
                            />
                          </a>
                        </h2>
                        <h4>
                          <a href>MYSORE & WAYANAD(3N/4D)TOUR</a>
                        </h4>
                        <p className="p-p">
                          <h5>Bangalore-Mysore-Wayanad</h5>
                          <br /> DAY 1 : BANGALORE TO MYSORE
                          <br /> DAY 2 : MYSORE SIGHTSEEING
                          <br /> DAY 3 : MYSORE TO WAYANAD
                          <br /> DAY 4 : EXPLORE WAYANAD & EVENING DEPARTURE
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <a className="a-1" href="/Package03">
                      <div className="icon-box-1">
                        {/* <div className="icon">
                        <i className="bx bxl-dribbble" />
                      </div> */}
                        <h2>
                          <a href="/Package03" className="logo">
                            <img
                              src="assets/img/Package3-06.jpg"
                              alt=""
                              className="img-fluid-1"
                            />
                          </a>
                        </h2>
                        <h4>
                          <a href>MYSORE,COORG & WAYANAD (5N/6D)</a>
                        </h4>
                        <p className="p-p">
                          <h5>Mysore-Coorg-Wayanad</h5>
                          <br /> DAY 1 : ARRIVE AT MYSORE
                          <br /> DAY 2 : MYSORE SIGHTSEEING
                          <br /> DAY 3 : MYSORE TO COORG
                          <br /> DAY 4 : EXPLORE COORG
                          <br />
                          DAY 5 : COORG TO WAYANAD
                          <br />
                          DAY 6 : WAYANAD SIGHTSEEING AND ENROUTE TO DEPARTURE
                          CITY
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <a className="a-1" href="/Package04">
                      <div className="icon-box-1">
                        {/* <div className="icon">
                        <i className="bx bxl-dribbble" />
                      </div> */}
                        <h2>
                          <a href="/Package04" className="logo">
                            <img
                              src="assets/img/Package4-07.jpg"
                              alt=""
                              className="img-fluid-1"
                            />
                          </a>
                        </h2>
                        <h4>
                          <a href>CHIKKAMAGALURU (02N/03D) TOUR</a>
                        </h4>
                        <p className="p-p">
                          <h5>Bangalore-Chikkamagaluru-Bangalore</h5>
                          <br /> DAY 1 : BANGALORE TO CHIKKAMAGALURU
                          <br /> DAY 2 : EXPLORE CHIKKAMAGALURU – BABA BUDAN
                          GIRI TREK
                          <br />
                          DAY 3 : EN-ROUTE BANGALORE
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                  >
                    <a className="a-1" href="/Package05">
                      <div className="icon-box-1">
                        {/* <div className="icon">
                        <i className="bx bxl-dribbble" />
                      </div> */}
                        <h2>
                          <a href="/Package05" className="logo">
                            <img
                              src="assets/img/Package3-09.jpg"
                              alt=""
                              className="img-fluid-1"
                            />
                          </a>
                        </h2>
                        <h4>
                          <a href>COORG AND WAYANAD TOUR – 04N/05D</a>
                        </h4>
                        <p className="p-p">
                          <h5>Bangalore-Coorg-Wayanad</h5>
                          <br /> Day 01 : Bangalore to Coorg
                          <br /> Day 02 : Coorg
                          <br /> Day 03 : Coorg to Wayanad
                          <br /> Day 04 : Wayanad
                          <br /> Day 05 : Wayanad to Bangalore
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slider-container">
                <Carousel
                  showArrows={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        className="arrow-button-1 left"
                        onClick={onClickHandler}
                        title={label}
                      >
                        &larr;
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        className="arrow-button-1 right"
                        onClick={onClickHandler}
                        title={label}
                      >
                        &rarr;
                      </button>
                    )
                  }
                >
                  {packages.map((packages) => (
                    <div key={packages.id} className="package-card-1">
                      <img
                        src={packages.image}
                        alt={packages.name}
                        className="package-image-1"
                      />
                      <h3 className="h3-pkg">{packages.name}</h3>
                      <p className="p-pkg">{packages.description}</p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </section>
          </div>
        </section>
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map" />
                      <h3>Our Address</h3>
                      <p>
                        #138, 2nd Main, East of NGEF, Kasturinagar, Bangalore
                        560043
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-envelope" />
                      <h3>Email Us</h3>
                      <p>
                        travel@transprofessionals.com
                        <br />
                        {/* info@transprofessionals.com */}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-phone-call" />
                      <h3>Call Us</h3>
                      <p>
                        +91 9060019000
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  onSubmit={handleSubmit}
                  role="form"
                  className="php-email-form"
                  // className="email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    {status === 'loading' && (
                      <div className="loading">Loading</div>
                    )}
                    {status === 'error' && (
                      <div className="error-message">Error sending message</div>
                    )}
                    {status === 'success' && (
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
        {/* </main> */}
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-info">
                    <h2>
                      <a href="/" className="logo">
                        <img
                          src="assets/img/logo-no-bg.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </h2>
                    <p className="pb-3">
                      <em>
                        We always Deliver Safety. Our Customer Centric approach
                        achive all safety goals. We operate with Service
                        Orientated mindset.
                      </em>
                    </p>
                    <p>
                      #138, 2nd Main, East of NGEF, Kasturinagar, <br />
                      Bangalore 560043
                      <br />
                      <br />
                      <strong>Phone:</strong> +91 9060019000
                      <br />
                      <strong>Email:</strong> travel@transprofessionals.com
                      <br />
                    </p>
                    <div className="social-links mt-3">
                      <a href="#" className="twitter">
                        <i className="bx bxl-twitter" />
                      </a>
                      <a href="#" className="facebook">
                        <i className="bx bxl-facebook" />
                      </a>
                      <a href="#" className="instagram">
                        <i className="bx bxl-instagram" />
                      </a>
                      <a href="#" className="google-plus">
                        <i className="bx bxl-skype" />
                      </a>
                      <a href="#" className="linkedin">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#about">About us</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Terms of service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Book a Cab</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Customer service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Package enquery </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Feed Back</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>
                    Please provide your email address for our offers and package
                    details.
                  </p>
                  <form action method="post">
                    <input type="email" name="email" />
                    <input type="submit" defaultValue="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              © Copyright{' '}
              <strong>
                <span>Transprofessionals Pvt Ltd</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/multi-responsive-bootstrap-template/ */}
              Designed by{' '}
              <a href="https://transprofessionals.com/">TransPro team</a>
            </div>
          </div>
        </footer>
        {/* End Footer */}
      </div>
    </div>
  );
};

export default Booking;
