import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import 'bootstrap/dist/css/bootstrap.min.css';

const packages = [
  // Array containing package details (replace with your actual package data)
  {
    id: 1,
    name: 'Package Trip to Goa',
    image: require('../assets/img/package-1.jpg'),
    description:
      'Experience the ultimate beach getaway with our package trip to Goa! Immerse yourself in the vibrant culture, indulge in delicious seafood, and unwind on pristine sandy shores. Explore ancient temples, enjoy thrilling water sports, and create memories that will last a lifetime in this tropical paradise.',
  },
  {
    id: 2,
    name: 'Package trip to Kerala',
    image: require('../assets/img/package-2.jpg'),
    description:
      'Embark on a mesmerizing journey through Kerala with our enticing package trip! Discover the enchanting backwaters as you cruise along serene canals, rejuvenate with Ayurvedic treatments, and witness the beauty of lush tea plantations. Immerse yourself in the rich heritage, savor delectable cuisine, and create unforgettable experiences in the land of tranquil beauty.',
  },
  {
    id: 3,
    name: 'Package tour to Jog Falls',
    image: require('../assets/img/package-3.jpg'),
    description:
      'Discover the breathtaking beauty of Jog Falls with our captivating package trip! Marvel at the magnificence of Indias second-highest waterfall as it plunges down a rugged cliff. Immerse yourself in the serene surroundings, explore verdant forests, and partake in exciting activities like trekking and photography. Experience the awe-inspiring grandeur of Jog Falls and create everlasting memories.',
  },
  {
    id: 4,
    name: 'Journey to Kanyakumari',
    image: require('../assets/img/package-4.jpg'),
    description:
      'Embark on a remarkable journey to Kanyakumari with our all-inclusive package! Witness the breathtaking convergence of the Indian Ocean, Arabian Sea, and Bay of Bengal at the southernmost tip of India. Immerse yourself in the cultural richness, visit iconic landmarks like the Vivekananda Rock Memorial and Thiruvalluvar Statue, and soak in stunning sunsets against the backdrop of this coastal paradise.',
  },
  {
    id: 5,
    name: 'Trip to Madikari',
    image: require('../assets/img/package-5.jpg'),
    description:
      'Experience the captivating charm of Madikari with our enticing package trip! Delight in the picturesque beauty of the Kaveri River as you embark on a serene river cruise. Immerse yourself in the rich wildlife, explore lush forests, and indulge in thrilling activities like bird watching and nature walks. Create unforgettable memories in the tranquil oasis of Madikari, where natures splendor awaits.',
  },
  {
    id: 6,
    name: 'Package 5',
    image: require('../assets/img/package-6.jpg'),
    description: 'Package 5 description',
  },
  {
    id: 7,
    name: 'Package 7',
    image: require('../assets/img/package-7.jpg'),
    description: 'Package 7 description',
  },
  {
    id: 8,
    name: 'Package 8',
    image: require('../assets/img/package-8.jpg'),
    description: 'Package 8 description',
  },
  {
    id: 9,
    name: 'Package 9',
    image: require('../assets/img/package-9.jpg'),
    description: 'Package 9 description',
  },
  {
    id: 10,
    name: 'Package 10',
    image: require('../assets/img/package-10.jpg'),
    description: 'Package 10 description',
  },
  {
    id: 11,
    name: 'Package 11',
    image: require('../assets/img/package-11.jpg'),
    description: 'Package 11 description',
  },
  {
    id: 12,
    name: 'Package 12',
    image: require('../assets/img/package-12.jpg'),
    description: 'Package 12 description',
  },
  {
    id: 13,
    name: 'Package 13',
    image: require('../assets/img/package-13.jpg'),
    description: 'Package 13 description',
  },
  {
    id: 14,
    name: 'Package 14',
    image: require('../assets/img/package-14.jpg'),
    description: 'Package 14 description',
  },
  // Add more package objects as needed
];

const Package01 = () => {
  console.log('##################### You have clicked  Package ##############');
  const [menu, setMenu] = useState(true);
  const [dropMenu, setDropMenu] = useState(true);
  const [deepMenu, setDeepMenu] = useState(true);
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);

  const [activeTab, setActiveTab] = useState('airport');

  const [phone, setPhone] = useState('');
  const [terminal, setTerminal] = useState('');
  const [pickUpDrop, setPickUpDrop] = useState('');
  const [pickupPoint, setPickupPoint] = useState('');
  const [dropPoint, setDropPoint] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [packageType, setPackageType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const dataToSend = 'Package01';
  // Store the data in localStorage
  console.log(dataToSend, '@#@#$#$$%$%^%$%^%$$');
  localStorage.setItem('dataToSend', dataToSend);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will run every 6 second!', counter);

      console.log('##########ADD#############');
      // setCounter(prev => prev + 1);
      setCounter((counter) => counter + 1);
      setCount((count) => count + 1);
      // handleCarouselRight();
    }, 6000);
    return () => clearInterval(interval);
  }, []);
  console.log('#####################', counter, '##############');
  if (counter === 5) {
    setCounter(0);
  }

  console.log('#####################', count, '##############');
  if (count === 5) {
    setCount(0);
  }

  function handleClick(e) {
    e.preventDefault();
    setMenu(!menu);

    console.log('#####################clicked ##############');
  }

  function handleDropdown(e) {
    e.preventDefault();
    setDropMenu(!dropMenu);
    console.log('#####################clicked ##############');
  }

  function handleDeepdown(e) {
    e.preventDefault();
    setDeepMenu(!deepMenu);
    console.log('#####################clicked ##############');
  }

  function handleCarouselLeft(e) {
    e.preventDefault();
    if (count === 0) {
      setCount(4);
    } else {
      setCount(count - 1);
    }

    console.log('#####################clicked ##############');
  }

  function handleCarouselRight(e) {
    e.preventDefault();
    if (count === 5) {
      setCount(0);
    } else {
      setCount(count + 1);
    }

    console.log('#####################clicked ##############');
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  //   const handleTabClick = (tab) => {
  //     setActiveTab(tab);
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     // Handle form submission logic here
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('############### TRY SENDING MAIL @@@@@@@@@@@@');
      console.log(
        '###############',
        name,
        email,
        subject,
        message,
        '@@@@@@@@@@@@'
      );
      const response = await axios.post('/send-email', {
        name,
        email,
        subject,
        message,
      });

      if (response.status === 200) {
        setStatus('success');
        // Reset form fields
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  //   const MyContainer = ({ className, children }) => {
  //     return (
  //       <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
  //         <CalendarContainer className={className}>
  //           <div style={{ background: "#f0f0f0" }}>
  //             What is your favorite day?
  //           </div>
  //           <div style={{ position: "relative" }}>{children}</div>
  //         </CalendarContainer>
  //       </div>
  //     );
  //   };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, onChange, placeholder }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
      ></input>
    )
  );

  return (
    <div>
      <div>
        {' '}
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Multi Bootstrap Template - Index</title>
        <meta content name="description" />
        <meta content name="keywords" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <link
          href={process.env.PUBLIC_URL + 'assets/img/favicon.png'}
          rel="icon"
        />
        <link
          href={process.env.PUBLIC_URL + 'assets/img/logo.png'}
          // href={process.env.PUBLIC_URL + 'assets/img/apple-touch-icon.png'}
          rel="apple-touch-icon"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/animate.css/animate.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
        <link
          href="assets/vendor/bootstrap/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/boxicons/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link
          href="assets/vendor/glightbox/css/glightbox.min.css"
          rel="stylesheet"
        />
        <link href="assets/vendor/remixicon/remixicon.css" rel="stylesheet" />
        <link
          href="assets/vendor/swiper/swiper-bundle.min.css"
          rel="stylesheet"
        />
        <link href="assets/css/style.css" rel="stylesheet" />
        <header-top id="header" className="fixed-top">
          <div className="container d-flex align-items-center justify-content-between sticked">
            {/* <h1 className="logo">
              <a href="/">Multi</a>
            </h1> */}
            {/* Uncomment below if you prefer to use an image logo */}
            <a href="/" className="logo">
              <img src="assets/img/logo.png" alt="" className="img-fluid" />
            </a>
            <nav id="navbar" className={menu ? 'navbar' : 'navbar-mobile'}>
              {/* <nav id="navbar" className="navbar"> */}
              <ul>
                <li>
                  <a className="nav-link scrollto " href="/">
                    {/* <a className="nav-link scrollto active" href="/"> */}
                    Home
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="/#about">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto" href="/#services">
                    Services
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/#portfolio">
                    Fleet
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Packages">
                    Package
                  </a>
                </li>
                <li>
                  <a className="nav-link scrollto " href="/Booking">
                    Booking
                  </a>
                </li>
                {/* <li>
                  <a className="nav-link scrollto" href="#team">
                    Team
                  </a>
                </li> */}
                {/* <li className="dropdown">
                  {/* <li className={dropMenu}> */}
                {/* <a href="#">
                    <span>Our Offerings</span>{' '}
                    <i
                      className="bi bi-chevron-down"
                      onClick={handleDropdown}
                    />
                  </a>
                  <ul className={dropMenu ? '' : 'dropdown-active'}>
                    <li>
                      <a href="#">Book a Cab</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">
                        <span>Customer service</span>{' '}
                        <i
                          className="bi bi-chevron-right"
                          onClick={handleDeepdown}
                        />
                      </a>
                      <ul className={deepMenu ? '' : 'dropdown-active'}>
                        <li>
                          <a href="#">@Bangalore Office</a>
                        </li>
                        <li>
                          <a href="#">@Booking Related Query</a>
                        </li>
                        <li>
                          <a href="#">@Billing Query</a>
                        </li>
                        <li>
                          <a href="#">@Package Query</a>
                        </li>
                        <li>
                          <a href="#">@Make a Payment </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Package enquiry</a>
                    </li>
                    <li>
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <a href="#">Feed Back</a>
                    </li>
                  </ul> */}
                {/* </li> */}
                {/* <li>
                  <a className="nav-link scrollto" href="#contact">
                    Contact
                  </a>
                </li> */}
                {/* <li>
                  <a className="getstarted scrollto" href="#about">
                    Get Started
                  </a>
                </li> */}
              </ul>
              <i
                className={
                  menu
                    ? 'bi bi-list mobile-nav-toggle'
                    : 'bi  mobile-nav-toggle bi-x'
                }
                onClick={handleClick}
              />
              {/* <i className="bi bi-list mobile-nav-toggle" /> */}
            </nav>
            <div>
              {/* <i className="transprofessionals">
                <p className="blankLineP">{`Transprofessionals `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              {/* <i className="toursAndTravelsPvtLtd">
                <p className="blankLineP">{`Tours and Travels Pvt Ltd `}</p> */}
              {/* <p className="blankLineP">&nbsp;</p> */}
              {/* <p className="p">&nbsp;</p> */}
              {/* </i> */}
              <div className="transprofessionals">Transprofessionals</div>
              <div className="toursAndTravelsPvtLtd">
                Tours and Travels Pvt Ltd{' '}
              </div>
            </div>

            {/* .navbar */}
          </div>
        </header-top>
        <section className="banner-p1" id="top">
          <div className="container" data-aos="">
            {/* //////////////////// */}
            {/* //////////////////// */}
            {/* ADD YOUR CODE HERE */}
            <div className="slider-container">
              <div className="long-page-p">
                {/* <div className=""> */}
                <br />
                <br />

                <h1> MYSORE & OOTY TOUR (3N/4D) </h1>
                <br />
                <br />
                <br />
                <br />
                <div className="row ">
                  <div className="col-p">
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/Package1-02.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt=""
                    />
                  </div>
                  <div className="col-p">
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/Package1-03.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt=""
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/Package1-04.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt=""
                    />
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <h2>THE NILGIRI CIRCUIT</h2>
                <br />
                <br />
                <br />
                <div className="row">
                  <div className="col-p">
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/Package1-05.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt="Image 1"
                    />
                  </div>
                  <div className="col-p">
                    <h3>OVERVIEW</h3>

                    <br />
                    <p className="p-p">
                      Nilgiri Circuit is one of the most beautiful & famous
                      circuit/trails of south India. The trail is a part of the
                      Western Ghats Mountain range. You will get to witness over
                      24 peaks rising above 2000m. Over 2600 species of
                      flowering plants, 150 species of flora, immaculate
                      diversity of birds and wildlife. When you embark on the
                      travel circuit from Bangalore, you will arrive at the most
                      eventful city in India with royal vibes with lush
                      greenery, people and nightlife. Mysore, located approx.
                      150kms from Bangalore is an architectural masterpiece that
                      showcases the grandeur of the Royal Wodeyar dynasty.
                      Experience the real charm of Mysore - Mysore Palace,
                      Jaganmohan Place and get drenched in history before
                      worshipping in Chamundeshwari Temple. From Mysore you will
                      reach the ‘Queen of Hills’- Ooty nestled in the Nilgiri
                      slopes. Here you will visit the Botanical Garden, Rose
                      Garden, Pykara River and get comfortable with the extreme
                      sides of Nature in Dolphin’s Nose, Sim’s Park, you will
                      also get to ride horses and ride boats.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-p">
                    <p className="p-p">
                      Overall, This spectacular trip of 3 nights 4 days will
                      offer a blend of modern city life, rich cultural heritage,
                      and tranquil natural beauty. This Journey provides a
                      perfect balance of exploration, relaxation and the
                      opportunity to create lasting memories!
                    </p>
                    <h3 className="p-p">QUICK INFO</h3>
                    <p className="p-p">
                      Best season to visit: April to June, September to November
                      Start point: Bengaluru End point: Bengaluru Duration:
                      3Nights-4Days Distance:
                    </p>
                    <h3 className="p-p">TRIP HIGHLIGHTS</h3>
                    <ul className="p-p">
                      <li>Sri Rangapatna</li>
                      <li>Brindavan Garden</li>
                      <li>Mysore Palace</li>
                      <li>Chamundeshwari Temple</li>
                      <li>Coonoor</li>
                      <li>Sim’s Park</li>
                      <li>Dolphins Nose</li>
                    </ul>
                  </div>
                  <div className="col-p">
                    <img
                      src={
                        process.env.PUBLIC_URL + 'assets/img/Package1-06.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt="Image 1"
                    />
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <h2>Day-wise Itinerary</h2>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div className="row">
                    <div className="col-p">
                      <h3>DAY 1 : BANGALORE TO MYSORE</h3>

                      <br />
                      <p className="p-p">
                        On your arrival at Bangalore, you will be welcomed by
                        our tour executive and after introduction to our
                        chauffer you can start your drive to Mysore - a city in
                        India's southwestern Karnataka state, was the capital of
                        the Kingdom of Mysore from 1399 to 1947. Enroute you can
                        visit Sri Rangapatna - The town takes its name from the
                        celebrated Renganathaswamy temple which dominates the
                        town, making Srirangapatna one of the most important
                        Vaishnavite centers of pilgrimage in South India. Later
                        you can start drive to Mysore. On reaching you will
                        check into the hotel. Rest for a while, have some
                        refreshments and in the evening you will visit Brindavan
                        garden. Return back to the hotel and spend the rest of
                        the night at Mysore.
                      </p>
                    </div>
                    <div className="col-p">
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-07.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />
                    </div>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="row">
                    <div className="col-p">
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-08.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />
                    </div>
                    <div className="col-p">
                      <h3>DAY 2 : MYSORE TO OOTY</h3>

                      <br />

                      <p className="p-p">
                        Post early morning Breakfast, start your sight-seeing of
                        Mysore by visiting the Mysore palace - is a historical
                        palace and a royal residence of the Wodeyar family. Then
                        you can visit Jaganmohan palace and conclude the
                        sightseeing by visiting Chamundeshwari Temple. Check out
                        from the hotel and get ready to hit the road to Ooty.
                        Enjoy the 3 hour drive up until you reach the hotel.
                        Take rest and freshenup so that you can go visit the
                        Ooty Lake - an artificial manmade lake wherein you can
                        go for boating for few hours. If you are interested
                        there you can enjoy activities like boat ride at Lake,
                        cycling, horse riding etc.., (On direct Payment basis).
                        Return back to the hotel and spend the rest of the night
                        at Ooty.
                      </p>
                    </div>
                  </div>

                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="row">
                    {/* <div className="col-p"> */}
                    <h3>DAY 3 : OOTY – COONOOR - OOTY</h3>

                    <br />

                    <p className="p-p">
                      Post breakfast you can visit Botanical garden - The
                      Gardens have around a thousand species, both exotic and
                      indigenous, of plants, shrubs, ferns, trees, and herbal
                      and bonsai plants. Then you can visit Rose garden - The
                      garden has been laid out with rose tunnels, pergolas and
                      bowers with rose creepers. Later you can visit Tea
                      Factory, Stephen Church, Thread Garden, and Stone House.
                      Post lunch you can start drive to Coonoor and visit Lambs
                      rock - it overlooks a sheer precipice of several hundred
                      meters with a phenomenal view of vast stretches of forest
                      all the way to the Coimbatore plains, later visit Dolphine
                      Nose, this is actually view point for a fabulous view of
                      the sunrise, you can get a beautiful view of the St.
                      Catherine falls from here, Later Visit Sim’s Park, it is a
                      famous park and the botanical garden together form the
                      largest repository of temperate plants in India and an
                      important destination for botanists. Sims’ Park is the
                      venue for the annual vegetable and fruit show. Apart from
                      these man made gardens, wild flowers like the kurinji,
                      wild sunflower, Lantana and a number of indigenous species
                      that adorn the mountains seasonally add to this park.
                      Return back to the hotel and spend the rest of the night
                      at Ooty.
                    </p>
                    {/* </div> */}
                  </div>

                  <div className="row">
                    <div className="col-p">
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-09.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />
                    </div>
                    <div className="col-p">
                      <h3>DAY 4 : ENROUTE BANGALORE</h3>

                      <br />

                      <p className="p-p">
                        Start your early morning road journey to Bangalore after
                        your Breakfast. Take back lovely memories with your
                        loved ones.
                      </p>
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-10.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />
                    </div>
                  </div>

                  <div>
                    <br />
                    <br />
                    <br />
                  </div>

                  <div className="row">
                    <div className="col-5">
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-11.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />
                      {/* <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-12.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      /> */}
                    </div>
                    <div className="col-p">
                      <h3>INCLUSIONS</h3>

                      <br />
                      <ul className="p-p">
                        <li>
                          Arrival and Departure assistance at the
                          Airport/Railway station/Residency.
                        </li>
                        <li>Daily Buffet Breakfast at the hotel.</li>
                        <li>
                          Air conditioned Vehicle for all Transfers &
                          Sightseeing as per the itinerary.
                        </li>
                        <li>Accommodation on Double Sharing Basis.</li>
                        <li>
                          All expenses related to vehicle inclusive of Toll,
                          fuel, parking.
                        </li>
                        <li>All government related taxes.</li>
                      </ul>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-5">
                      <img
                        src={
                          process.env.PUBLIC_URL + 'assets/img/Package1-13.jpg'
                        }
                        className="img-fluid-1 img-p"
                        alt="Image 1"
                      />

                      <div className="row">
                        <div className="col-6">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/img/Package1-14.jpg'
                            }
                            className="img-fluid-1 img-p"
                            alt="Image 1"
                          />
                        </div>
                        <div className="col-6">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              'assets/img/Package1-15.jpg'
                            }
                            className="img-fluid-1 img-p"
                            alt="Image 1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-p">
                      <h3>EXCLUSIONS</h3>

                      <br />
                      <ul className="p-p">
                        <li>
                          Guide and Entrance Fee at the monuments and at
                          sightseeing places.
                        </li>
                        <li>Lunch & Dinner at the hotel</li>
                        <li>
                          Any personal expenses such as telephone, laundry bills
                          etc.
                        </li>
                        <li>Any additional activities.</li>
                        <li>
                          Package price does not include Gala dinner charges
                          applicable on Christmas and New Year's Evening
                        </li>
                      </ul>

                      <br />
                      <div>
                        <br />
                        <br />
                        <br />
                      </div>
                      <h3>NOTE</h3>
                      <ul className="p-p">
                        <li>All transportation type is private</li>
                        <li>Rates might change subjective to market rates.</li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="col">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        'assets/img/Package-1-Banner.jpg'
                      }
                      className="img-fluid-1 img-p"
                      alt="Image 1"
                    />
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="page-direction-button">
                    <a href="/Booking">
                      <i className="fa fa-phone" />
                      Book Now
                    </a>
                  </div>
                  {/* Add more text and photos as needed */}
                </div>
              </div>
            </div>
            {/* //////////////////// */}
            {/* //////////////////// */}
          </div>
        </section>
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map" />
                      <h3>Our Address</h3>
                      <p>
                        #138, 2nd Main, East of NGEF, Kasturinagar, Bangalore
                        560043
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-envelope" />
                      <h3>Email Us</h3>
                      <p>
                        travel@transprofessionals.com
                        <br />
                        {/* info@transprofessionals.com */}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="bx bx-phone-call" />
                      <h3>Call Us</h3>
                      <p>
                        +91 9060019000
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form
                  onSubmit={handleSubmit}
                  role="form"
                  className="php-email-form"
                  // className="email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="my-3">
                    {status === 'loading' && (
                      <div className="loading">Loading</div>
                    )}
                    {status === 'error' && (
                      <div className="error-message">Error sending message</div>
                    )}
                    {status === 'success' && (
                      <div className="sent-message">
                        Your message has been sent. Thank you!
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
        {/* </main> */}
        {/* End #main */}
        {/* ======= Footer ======= */}
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-info">
                    <h2>
                      <a href="/" className="logo">
                        <img
                          src="assets/img/logo-no-bg.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </h2>
                    <p className="pb-3">
                      <em>
                        We always Deliver Safety. Our Customer Centric approach
                        achive all safety goals. We operate with Service
                        Orientated mindset.
                      </em>
                    </p>
                    <p>
                      #138, 2nd Main, East of NGEF, Kasturinagar, <br />
                      Bangalore 560043
                      <br />
                      <br />
                      <strong>Phone:</strong> +91 9060019000
                      <br />
                      <strong>Email:</strong> travel@transprofessionals.com
                      <br />
                    </p>
                    <div className="social-links mt-3">
                      <a href="#" className="twitter">
                        <i className="bx bxl-twitter" />
                      </a>
                      <a href="#" className="facebook">
                        <i className="bx bxl-facebook" />
                      </a>
                      <a href="#" className="instagram">
                        <i className="bx bxl-instagram" />
                      </a>
                      <a href="#" className="google-plus">
                        <i className="bx bxl-skype" />
                      </a>
                      <a href="#" className="linkedin">
                        <i className="bx bxl-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#about">About us</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Terms of service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Book a Cab</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Customer service</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Package enquery </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Downloads</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{' '}
                      <a href="#">Feed Back</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>
                    Please provide your email address for our offers and package
                    details.
                  </p>
                  <form action method="post">
                    <input type="email" name="email" />
                    <input type="submit" defaultValue="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              © Copyright{' '}
              <strong>
                <span>Transprofessionals Pvt Ltd</span>
              </strong>
              . All Rights Reserved
            </div>
            <div className="credits">
              {/* All the links in the footer should remain intact. */}
              {/* You can delete the links only if you purchased the pro version. */}
              {/* Licensing information: https://bootstrapmade.com/license/ */}
              {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/multi-responsive-bootstrap-template/ */}
              Designed by{' '}
              <a href="https://transprofessionals.com/">TransPro team</a>
            </div>
          </div>
        </footer>
        {/* End Footer */}
      </div>
    </div>
  );
};

export default Package01;
