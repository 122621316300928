import {
  Routes,
  Route,
  // useNavigationType,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import Packages from './pages/Packages';
import Package1 from './pages/Package01';
import Package2 from './pages/Package02';
import Package3 from './pages/Package03';
import Package4 from './pages/Package04';
import Package5 from './pages/Package05';
import Booking from './pages/Booking';
// import About from './pages/About';
// import Services from './pages/Services';
// import Pricing from './pages/Pricing';
// import Contact from './pages/Contact';
// import ServiceDetails from './pages/ServiceDetails';
// import GetAQuotes from './pages/GetAQuotes';
// import Login from './pages/Login';

import { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


function App() {
  // const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // useEffect(() => {
  //   if (action !== 'POP') {
  //     window.scrollTo(0, 0);
  //   }
  // }, [action]);

  useEffect(() => {
    ReactGA.initialize('G-QNDLYCP4JQ'); // Replace with your own Google Analytics ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    ReactPixel.init('999589334454454', {
      autoConfig: true, // Automatic configuration based on your Facebook Pixel settings
    });
  }, []);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    //TODO: Update meta titles and descriptions below
    switch (pathname) {
      case '/':
        title = 'Home Page';
        metaDescription = '';
        break;
      case '/Packages':
        title = 'Packages';
        metaDescription = '';
        break; 
        case '/Package01':
        title = 'Package01';
        metaDescription = '';
        break;
        case '/Package02':
          title = 'Package02';
          metaDescription = '';
          break;  
          case '/Package03':
          title = 'Package03';
          metaDescription = '';
          break; 
           case '/Package04':
          title = 'Package04';
          metaDescription = '';
          break; 
           case '/Package05':
          title = 'Package05';
          metaDescription = '';
          break;
      case '/Booking':
        title = 'Booking';
        metaDescription = '';
        break;
      // case '/About':
      //   title = 'AboutUs';
      //   metaDescription = '';
      //   break;
      // case '/Pricing':
      //   title = 'Pricing';
      //   metaDescription = '';
      //   break;
      // case '/ServiceDetails':
      //   title = 'ServiceDetails';
      //   metaDescription = '';
      //   break;
      // case '/Contact':
      //   title = 'Contact';
      //   metaDescription = '';
      //   break;
      // case '/GetAQuotes':
      //   title = 'GetAQuotes';
      //   metaDescription = '';
      //   break;
      //   case '/Login':
      //   title = 'Login';
      //   metaDescription = '';
      //   break;
      default:
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Packages" element={<Packages />} />
      <Route path="/Package01" element={<Package1 />} />
      <Route path="/Package02" element={<Package2 />} />
      <Route path="/Package03" element={<Package3 />} />
      <Route path="/Package04" element={<Package4 />} />
      <Route path="/Package05" element={<Package5 />} />
      <Route path="/Booking" element={<Booking />} />
      {/* <Route path="/About" element={<About />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Pricing" element={<Pricing />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/GetAQuotes" element={<GetAQuotes />} />
      <Route path="/ServiceDetails" element={<ServiceDetails />} />
      <Route path="/Login" element={<Login />} /> */}
      {/*     
      <Route path="/Test" element={<Test />} /> */}
    </Routes>
  );
}
export default App;
